<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Order</h1>
                        <img id="logoId" src="../../../public/images/logo.png" style="width:40px; height:40px; display:none;">
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="from_status">From Status <span class="text-danger" title="Required">*</span></label>
                                            <v-select name="from_status"
                                                v-model="search.from_status"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= statusList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                            
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="to_status">To Status <span class="text-danger" title="Required">*</span></label>
                                            <v-select name="to_status"
                                                v-model="search.to_status"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= statusList
                                                :readonly=1
                                                :disabed=1
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                            
                                        </div>
                                    </div>
                                    <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="date">Activity Date <span class="text-danger" title="Required">*</span></label>
                                            <input type="date" id="date" v-model="search.activity_date" class="form-control">    
                                            
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-2">
                                        <div class="form-group">
                                            <label for="mobile">Merchant </label>
                                            <v-select name="destination_hub_id"
                                                v-model="search.merchant_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= merchantList
                                                :placeholder="$t('globalTrans.select')"
                                            />
                                            
                                        </div>
                                    </div> -->
                                    <div class="col-md-2" v-if="authUser.role_id != 7">
                                        <div class="form-group">
                                            <label for="mobile">Destination Hub <span class="text-danger" title="Required">*</span></label>
                                            <v-select name="destination_hub_id"
                                                v-model="search.destination_hub_id"
                                                label="text"
                                                :reduce="item => item.id"
                                                :options= hubList
                                                :placeholder="$t('globalTrans.select')"
                                            />                                            
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group" style="margin-top:28px;">
                                            <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="searchData" ><i class="fa fa-search"></i></button>
                                            <button class="btn btn-warning btn-sm" @click.prevent="reload"><i class="fa fa-sync-alt"></i></button>
                                            <b style="margin-left:20px; font-size:20px">Total: {{ totalParcel }}</b>
                                            <b style="margin-left:20px; font-size:20px">Scaned: {{ barcodes.length }}</b>
                                            <b style="margin-left:20px; font-size:20px" v-if="totalPending > 0"><router-link target="_blank" :to="'/order?date_parameter_id=1&date_type_id=2&destination_hub_id='+search.destination_hub_id+'&query_status='+ search.from_status">Pending: {{ totalPending }}</router-link></b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-md-3"></div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" v-model="itemId" @keyup.enter="keepOrder" placeholder="Scan barcode or type order id and press enter">    
                                        </div>
                                    </div>
                                    <div class="col-md-2" v-if="barcodes.length > 0">
                                        <div class="form-group">
                                            <button class="btn btn-info btn-sm mr-2" type="submit" @click.prevent="processSubmit" >Submit</button>
                                        </div>
                                    </div>
                                    <div class="col-md-3"></div>
                                </div>
                            </div>
                            <div class="card-body">
                                <b-overlay :show="loader">
                                    <div class="table-responsive">
                                        <table class="table table-bordered table-striped table-sm text-center"> 
                                            <thead>
                                                <tr class="text-center">
                                                    <th style="width:8%">Action</th>
                                                    <th style="width:12%">Order ID</th>
                                                    <th style="width:10%">Merchant</th>
                                                    <th style="width:12%">Customer</th>
                                                    <th style="width:14%">Address</th>
                                                    <th style="width:10%">Status</th>  
                                                    <th style="width:10%">Destination</th>
                                                    <th style="width:6%">Order Date</th>
                                                    <th style="width:6%">Price</th>                             
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in listData" :key="index.id">
                                                    <td style="width:8%">
                                                        <!-- <button class="btn btn-success btn-sm mr-2" @click="printPos(item)">
                                                            POS
                                                        </button> -->
                                                        <button class="btn btn-warning btn-sm mr-2" @click="printLabel(item)">
                                                            Label
                                                        </button>
                                                    </td>
                                                    <td style="width:12%">{{ item.id }}</td>
                                                    <td style="width:10%" @click="editModal(key, order)">{{ item.merchant.business }}</td>
                                                    <td style="width:12%">{{ item.name }}, {{ item.mobile }}{{ item.mobile_2 ? ', '+ item.mobile_2 : '' }}</td>
                                                    <td style="width:14%">{{ item.address }}</td>
                                                    <td class="text-center"><span :class="'status-'+item.status">{{ getStatus(item.status) }}</span></td>                                    
                                                    <td class="text-center">{{ item.destination ? item.destination.name : '' }}</td>
                                                    <td style="width:6%">{{ item.date }}</td>
                                                    <td style="width: 6%">{{ item.price }}</td>
                                                </tr>
                                            </tbody>
                                        </table> 
                                    </div>
                                </b-overlay>
                            </div>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </section>
        <!-- /.content -->
    </div>
</template>
<script>

import config from '@/config'
import Pos from './Pos.js'
import Label from './Label.js'
import moment from 'moment'

export default {
    name:'Bank',
    data(){
        return {
            loader: false,
            search: {
                from_status: '',
                to_status: '',
                activity_date : moment().format('YYYY-MM-DD'),
                destination_hub_id: '',
                merchant_id: ''
            },
            listData:[],
            printOrders:[],
            totalParcel: 0,
            totalPending: 0,
            scanTotal: 0,
            statusList: [
                { text: 'Picked', id: 3 },
                { text: 'At Sorting Hub', id: 4 },
                { text: 'At Destination Hub', id: 5 },
                { text: 'In Transit', id: 10 }
            ],
            itemId: '',
            barcodes: []
        }
    },
    watch: {
        'search.from_status': function (newVal, oldVal) {
            if (newVal != oldVal) {
                if (newVal == 3) {
                    this.search.to_status = 4
                } else if (newVal == 4) {
                    this.search.to_status = 10
                } else if (newVal == 10) {
                    this.search.to_status = 5
                } else {
                    this.search.to_status = ''
                }
            }
        }
    },
    computed : {
        authUser () {
            return this.$store.state.authUser
        },
        hubList () {
            return this.$store.state.commonObj.hubList
        },
        merchantList () {
            return this.$store.state.commonObj.merchantList
        }
    },
    created () {
        this.loadData();
    },
    methods:{
        searchData () {
            this.loadData()
        },
        async loadData(){     
            this.scanTotal = 0
            this.barcodes = []
            this.loader = true 
            const params = Object.assign(this.search, { destination_hub_id: this.authUser.role_id == 7 ? this.authUser.hub_id : this.search.destination_hub_id })
            const response = await config.getData('/order/process', params)
            this.loader = false
            if (response.status == 200){       
                this.totalParcel = response.data.length         
                this.totalPending = response.totalPending         
                this.listData = response.data
            } else {
                this.totalParcel = 0
                this.totalPending = response.totalPending  
                this.listData = []
                this.$refs.editForm.setErrors(response.errors)
            } 
        },
        getStatus (status) {
            const tmpStatus = this.statusList.find(tmp => tmp.id == status)
            return typeof tmpStatus != 'undefined' ? tmpStatus.text : status
        },
        getBase64Logo (logo) {
            var canvas = document.createElement('canvas')
            canvas.width = logo.width
            canvas.height = logo.height
            var ctx = canvas.getContext('2d')
            ctx.drawImage(logo, 0, 0)
            var dataURL = canvas.toDataURL('image/png')
            return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
        },
        printPos(item) {
            this.printOrders = []
            this.printOrders.push(item)
            const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
            Pos.expostPosDetails(base64Logo, this.printOrders)
        },
        printLabel(item) {
            this.printOrders = []
            this.printOrders.push(item)
            const base64Logo = this.getBase64Logo(document.getElementById('logoId'))
            Label.exportLabelDetails(base64Logo, this.printOrders)
        },
        reload () {
            this.search = Object.assign({}, {
                from_status: '',
                to_status: '',
                date : moment().format('YYYY-MM-DD'),
                destination_hub_id: ''
            })
        },
        keepOrder() {
            const tmpItem = this.listData.find(el => el.id === parseInt(this.itemId))
            if (typeof tmpItem !== 'undefined') {
                this.barcodes.push(this.itemId)
                this.listData = this.listData.filter(el => el.id !== parseInt(this.itemId))
                this.itemId = ''
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: 'Sorry, parcel not found',
                    color: '#dc3545'
                })
                this.itemId = ''
            }            
        },
        async processSubmit(){     
            this.loader = true 
            const params = Object.assign(this.search, { order_ids: this.barcodes })
            const response = await config.postData('/order/process-submit', params)
            this.loader = false
            if (response.success){    
                this.barcodes=[]   
                this.searchData()     
                this.$toast.success({
                    title: 'Success',
                    message: 'Action submitted successfully',
                    color: '#218838'
                })
            } else {
                if(response.errors) {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Please select destination hub',
                        color: '#dc3545'
                    })
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })
                }
                
            } 
        },
    }
}
</script>